<template>
<PageWithHeader>
  <template #title>
    <t :path="i18nByType[eventType].pageTitle">NFP</t>
  </template>
  <template #body>
    <div class="content-main px-16 pt-16 pb-32">
      <Holder :content="info" :skeleton-repeat="4">
        <div class="main-bg br-lg px-16 py-32"
          style="background-image: url(/img/big_news/USA-BG@2x.png);">
          <div class="title df-middle">
            <Pic src="big_news/USDX" width="32" height="32" class="mr-12" />
            <t style="font-size: 21px;" as="p" :path="i18nByType[eventType].mainTitle"></t>
          </div>
          <div class="date d-f mt-24">
            <div class="date-item br-lg flex-2 mr-8" style="word-spacing:8px">
              {{prop('md', info)}}
            </div>
            <div class="date-item br-lg flex-1">{{prop('hs', info)}}</div>
          </div>

          <div class="params br-lg mt-24 df-middle">
            <div class="p-item flex-1 df-col df-center df-middle"
              style="border-right: 1px solid #4A5983">
              <t path="bigevents_2">Actual</t>
              <div class="loading" v-if="loading"></div>
              <p v-else
                class="f-bold"
                :class="{'c-success':actual&&actual.up, 'c-danger':actual&&actual.down}"
                style="font-size: 21px;">
                {{actual && actual.actual || '--'}}
              </p>
            </div>
            <div class="p-item flex-1 df-col df-center df-middle"
              style="border-right: 1px solid #4A5983">
              <t path="bigevents_3">Forecast</t>
              <p class="f-bold" style="font-size: 21px;">{{prop('forecast', info)}}</p>
            </div>
            <div class="p-item flex-1 df-col df-center df-middle">
              <t path="bigevents_4">Previous</t>
              <p class="f-bold" style="font-size: 21px;">{{prop('previous', info)}}</p>
            </div>
          </div>

          <div class="bottom mt-24" v-if="info && info.affectedCodes.length>0">
            <t path="bigevents_5">Most affected by NFP</t>
            <div class="tips mt-8">
                <span class="br-sm mr-8 mb-4"
                  v-for="(item, index) in info.affectedCodes"
                  :key="index"
                  @click="enterInfo(item)">{{item}}</span>
            </div>
          </div>
        </div>

        <div class="text px-16" v-for="(item, index) in i18nByType[eventType].texts" :key="index">
          <t class="f-lg mt-24 f-bold mb-8" as="p" :path="item.title"></t>
          <t class="c-title mt-8" as="div" :path="item.content" multiline #="{td}" custom>
            <p class="mb-8" v-for="(p, index) in td" :key="index">{{p}}</p>
          </t>
        </div>

      </Holder>
    </div>
  </template>
</PageWithHeader>
</template>
<script lang="ts">
import { defineComponent, onBeforeUnmount, shallowRef } from 'vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import Pic from '@/components/Pic.vue'
import { readBigNewsInfo, readBigNewsActual } from '@/pages/bigNews/bigNews.api'
import { useRoute, useRouter } from 'vue-router'
import Holder from '@/provider/Holder.vue'
import { formatDateTime } from '@/common/format'
import i18nByType from '@/pages/bigNews/bigNews'

const ONE_MINUTE = 60

export default defineComponent({
  name: 'BigNews',
  components: {
    PageWithHeader,
    Pic,
    Holder,
  },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const info = shallowRef()
    const actual = shallowRef()
    const eventId = route.query.id
    const eventType = Number(route.query.type)
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September',
      'October', 'November', 'December',
    ]
    const loading = shallowRef(false)
    let cdTimer = 0
    let loadingTimer = 0

    readBigNewsInfo({
      eventId: eventId,
    }).then(res => {
      const date = new Date(res.time)
      info.value = res
      info.value.md = months[date.getMonth()] + '  ' + formatDateTime('dd', Number(date))
      info.value.hs = formatDateTime('HH:mm', Number(date))
      readActual()
    })

    const clearTimer = () => {
      clearTimeout(cdTimer)
      clearTimeout(loadingTimer)
    }

    const readActual = () =>
      readBigNewsActual({
        eventId: eventId,
      }).then(res => {
        actual.value = res

        if (res.actual) {
          actual.value.up = parseFloat(res.actual) > parseFloat(info.value.forecast)
          actual.value.down = parseFloat(res.actual) < parseFloat(info.value.forecast)
        }

        if (res.actual) {
          clearTimer()
          loading.value = false
        } else if (res.time > ONE_MINUTE) { // 剩余60s启动刷新
          cdTimer = window.setTimeout(readActual, (res.time - ONE_MINUTE) * 1000)
        } else if (res.time > 0 || !res.actual) { // 存在倒计时或者没有实际值时每6s请求一次
          loading.value = true
          loadingTimer = window.setTimeout(readActual, 1000 * 6)
        }
      })

    const enterInfo = (code: string) => {
      router.push('/symbol/' + code)
    }

    onBeforeUnmount(clearTimer)

    return {
      info,
      actual,
      eventType,
      i18nByType,
      enterInfo,
      loading,
    }
  },
})
</script>

<style>
@keyframes loading {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>

<style scoped lang="scss">
.main-bg {
  background-size: 100%;
  background-repeat: no-repeat;
  color: #fff;
}

.date-item {
  height: 48px;
  line-height: 48px;
  background: #D02F44;
  text-align: center;
  font-weight: bold;
  font-size: 27px;
}

.params {
  border: 1px solid #4A5983;
  height: 72px;

  .p-item {
    height: 46px;
  }
}

.tips span {
  padding: 4px 8px;
  display: inline-block;
  background: rgba(50, 107, 254, 0.5);
}

.loading {
  height: 20px;
  width: 20px;
  background-image: url(/img/loading@2x.png);
  background-size: 16px 16px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  animation: loading 1s linear 0s infinite running;
}
</style>
